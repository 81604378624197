@use "grid";

.recommendations {
  .header {
    margin-bottom: 20px;

    @media (max-width: grid.$md) {
      font-size: 1.8rem;
    }
  }

  .comp-disclosure {
    background-color: #f7f7f7;
    border-radius: 5px;
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 600px;
    padding: 12.5px 15px;
    text-align: left;
    width: 90%;
  }

  .group {
    background-color: #f7f7f7;
    border: 0;
    border: 1px solid transparent;
    cursor: pointer;
    flex: 1;
    font-size: 14px;
    min-width: 33.333%;
    outline: none !important;
    padding: 15px;

    @media (max-width: 767px) {
      min-width: 50%;
    }

    &.active {
      background-color: #bae8e8;
      font-weight: 400;
    }

    &:focus {
      border-color: #333 !important;
    }
  }

  .choices,
  .groups {
    cursor: pointer;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 90%;
    width: 600px;
  }

  .divider {
    background-color: #f7f7f7;
    height: 2px;
    line-height: 1.6;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 90%;
    width: 600px;
  }

  .explanation {
    background-color: #f7f7f7;
    line-height: 1.6;
    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;

    &.warning {
      background-color: #fff6c2;
    }

    &.tip {
      background-color: #bae8c0;
    }
  }

  .recommendation {
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 90%;
    width: 600px;

    .product {
      .image {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        margin-top: 50px;
        width: 120px;
      }

      .title {
        color: #111;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 20px;
        text-align: center;
      }

      .company {
        color: #666;
        font-size: 18px;
        font-weight: 300;
        text-align: center;
      }

      .link {
        align-items: center;
        display: flex;
        justify-content: center;
        text-decoration: none;
        width: 200px;

        .lock {
          margin-left: 7.5px;
          position: relative;
          width: 15px;
        }

        .disclosure {
          font-size: 14px;
          font-style: italic;
          margin-top: 5px;
        }
      }
    }
  }
}
