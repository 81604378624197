.content {
  .outer-container {
    align-items: center;
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 100px;
    width: 100%;
  }

  .inner-container {
    align-items: center;
    // flex-wrap: wrap;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    max-width: 850px;
    padding-bottom: 30px;
    width: 95%;
  }

  .divider {
    display: inline-block;
    height: 1px;
    margin-bottom: 45px;
    margin-top: 7.5px;
    width: 125px;
  }

  @media (max-width: 700px) {
    .inner-container {
      width: 90%;
    }

    .jumbo {
      width: 95%;
    }
  }

  .inner-container a {
    color: #333;
    text-decoration: none;
  }

  .row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .icon-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon-link {
    align-items: center;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none !important;
    padding: 20px;
    text-decoration: none;

    &:hover,
    &:focus {
      border: 1px solid #333;
    }
  }

  .icon {
    height: 130px;
    width: 130px;
  }

  .icon-title {
    color: #333;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }

  .modal {
    background-color: #fff;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      height: 550px;
      overflow-y: scroll;
    }

    .modal-icon-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
      padding-bottom: 15px;
    }
  }

  @media (min-width: 701px) {
    .row {
      margin: 0 0 20px 0;
      padding: 0 100px;
    }

    .icon-container {
      margin: 10px 0;
      width: 215px;
    }
  }
}
