@use "grid";

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: grid.$md) {
    margin: 0 auto;
    width: 90%;
  }

  .header {
    &.accented {
      background-color: #f7f7f7;
      display: inline-block;
      font-size: 2rem;
      padding: 10px 0;
      text-align: center;
      width: 240px;

      @media (max-width: grid.$md) {
        font-size: 1.8rem;
        width: 220px;
      }
    }
  }

  .divider {
    background-color: #f7f7f7;
    height: 5px;
    margin-top: 50px;
    max-width: 500px;
    width: 90%;
  }

  .subtitle {
    color: #666;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0 auto;
    margin-top: 40px;
    max-width: 90%;
    text-align: left;
    width: 680px;

    &.explanation {
      background-color: #f7f7f7;
      padding: 20px;
    }

    a {
      color: #666;
    }
  }

  .earth {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 40px;
    max-width: 60%;
    width: 300px;
  }

  .comments {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 600px;

    .comment {
      background-color: #f7f7f7;
      line-height: 1.6;
      margin: 10px;
      padding: 20px;
      width: 90%;
    }
  }

  .cta {
    margin-top: 50px;
  }
}
