@use "grid";

.header {
  color: #333;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.6;
  margin: 0 auto;
  margin-top: 60px;
  text-align: center;

  @media (max-width: grid.$md) {
    font-size: 2rem;
    margin-top: 50px;
    width: 90%;
  }
}
