@use "colors";
@use "grid";

.navbar {
  background-color: colors.$purple;
  height: 70px;
  width: 100%;

  .tab {
    color: white;
    font-weight: 500;
    margin-right: 20px;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }

    @media (max-width: grid.$md) {
      font-size: 14px;
      margin-right: 12.5px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 900;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: grid.$md) {
      font-size: 18px;
      margin-left: 17.5px;
      margin-right: 15px;
    }
  }
}

.footer {
  align-items: flex-start;
  background-color: white;
  border-top: 1px solid #ccc;

  flex-wrap: wrap;
  font-size: 14px;
  padding: 20px;

  > div {
    flex: 1;
    padding: 0 40px;

    @media (max-width: 768px) {
      min-width: 100%;
    }

    a {
      color: colors.$purple;
      font-weight: 700;
    }

    &.legal {
      a {
        display: inline;
      }
    }
  }

  h2 {
    font-size: 15px;
    font-weight: 700;
    margin: 10px 0;
  }

  p {
    color: #333;
    line-height: 1.6;
  }
}
