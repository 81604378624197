.fr {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.frc {
  align-items: center;
  display: flex;
  justify-content: center;
}

.fcc {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

$md: 767px;
