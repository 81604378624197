.wrap {
  flex-wrap: wrap;
}

.center {
  margin: 0 auto;
}

.m10 {
  margin: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb75 {
  margin-bottom: 75px;
}
