@use "sass:color";
@use "colors";

.button {
  background-color: colors.$purple;
  border: 0;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 30px;
  padding: 20px;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: color.adjust($color: colors.$purple, $lightness: -10%);
  }
}
